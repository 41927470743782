import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { UserInfo } from '../../auth/schemas';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
})
export class AppSidebarComponent implements OnInit {
    timeout: ReturnType<typeof setTimeout> | null = null;
    currentUserInfo: UserInfo | undefined = undefined;

    @ViewChild('menuContainer') menuContainer!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        public el: ElementRef,
        private readonly localStorage: LocalStorageService,
    ) {}

    ngOnInit(): void {
        this.currentUserInfo = this.localStorage.getUserInfo();
    }
    onMouseEnter() {
        if (!this.layoutService.state.anchored) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.layoutService.state.sidebarActive = true;
        }
    }

    onMouseLeave() {
        if (!this.layoutService.state.anchored) {
            if (!this.timeout) {
                this.timeout = setTimeout(() => (this.layoutService.state.sidebarActive = false), 300);
            }
        }
    }

    anchor() {
        this.layoutService.state.anchored = !this.layoutService.state.anchored;
    }
}
