import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { takeWhile, tap } from 'rxjs';
import { UserInfo } from '../../auth/schemas';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {
    @Input() shouldShowProviders: boolean = true;
    menuItems: MenuItem[] = [];
    currentUserInfo: UserInfo | undefined = undefined;

    destroyed = false;

    constructor(
        private readonly appLayoutService: LayoutService,
        private localStorageService: LocalStorageService,
    ) {}

    ngOnInit() {
        this.appLayoutService.menuItems$
            .pipe(
                takeWhile(() => !this.destroyed),
                tap((items) => {
                    this.menuItems = items;
                }),
            )
            .subscribe();
        this.currentUserInfo = this.localStorageService.getUserInfo();
    }

    ngOnDestroy() {
        this.destroyed = true;
    }
}
