import { Injectable } from '@angular/core';
import { LoginResponse } from '../schemas';
import { UserInfo } from '../../auth/schemas';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly accessTokenKey = 'accessToken';
    private readonly refreshTokenKey = 'refreshToken';
    private readonly userInfoKey = 'userInfo';
    constructor() {}

    setTokens(response: LoginResponse) {
        localStorage.setItem(this.accessTokenKey, response.access_token);
        localStorage.setItem(this.refreshTokenKey, response.refresh_token);
    }

    clearLocalStorage() {
        localStorage.removeItem(this.accessTokenKey);
        localStorage.removeItem(this.refreshTokenKey);
        localStorage.removeItem(this.userInfoKey);
    }

    getAccessToken() {
        return localStorage.getItem(this.accessTokenKey);
    }

    getRefreshToken() {
        return localStorage.getItem(this.refreshTokenKey);
    }

    setUserInfo(userInfo: UserInfo) {
        localStorage.setItem(this.userInfoKey, JSON.stringify(userInfo));
    }

    getUserInfo(): UserInfo | undefined {
        const userInfo = localStorage.getItem(this.userInfoKey);
        if (userInfo != null) {
            return JSON.parse(userInfo);
        }
        return undefined;
    }
}
